// alpine
import Alpine from 'alpinejs';
import ui from '@alpinejs/ui';
import focus from '@alpinejs/focus';
import GLightbox from 'glightbox';
import AOS from "aos";

Alpine.plugin(ui);
Alpine.plugin(focus);
window.Alpine = Alpine;
Alpine.start();

window.addEventListener("load", (event) => {
    AOS.init({
        once: "true",
    });
});

let lightboxVideo = GLightbox({
    selector: '.glightbox-video'
});

let scrollancherLinks = document.querySelectorAll('a[href^="#"]');
scrollancherLinks.forEach(link => {
    link.addEventListener('click', function(event) {
        //event.preventDefault();
        let target = this.getAttribute('href');
        if (target) {
            smoothScroll(target);
        }
    });
});

function smoothScroll(target) {
    let targetElement = document.querySelector(target);

    if (targetElement) {
        window.scrollTo({
            top: targetElement.offsetTop - 85,
            behavior: 'smooth'
        });
    }
}